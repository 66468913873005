import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import * as objectsSlice from '../objects/objectsSlice';
import * as documentSlice from '../document/documentSlice';
import * as modalSlice from '../modal/modalSlice';
import * as api from './imageAPI';
import { dateHelper } from '../../utils/dateHelpers';
import { idsMatch, isValidValue } from '../../utils/HelperFunctions';
import {
  getMarkingId,
  markingIdsMatch,
  markingIdMatch,
  updateMarkingDataFromPayload,
  resetMarkingData
} from '../floorPlan/markingFunctions';

import { differenceInMilliseconds } from 'date-fns';

const saveIntervals = {};
const saveWaits = {};
const deleteIntervals = {};
const deleteWaits = {};
const saveStatus = {};
const deleteStatus = {};
const retrySaveTimeouts = {};
const retryDeleteTimeouts = {};

const initialState = {
  markingStatus: 'idle',
  markingStatuses: {},  // Individual status for every marking, for example when copying marking 
  markings: {}, // objectIds are keys. We must keep list of floorPlan and document markings at the same time.

  defaultValues: {
    color: { value: 'rgb(230,25,75)', name: 'red' },
    fillColor: { value: 'transparent', name: 'transparent' },
    shapeName: '',
    strokeWidth: 1,
    strokeDasharray: 0,
    fillOpacity: 0.5,
    showTextInput: false,
    svgViewBoxWidth: 300,
    svgViewBoxHeight: 300,
    zoomTransform: { x: 0, y: 0, scale: 1 }
  },
  // These values are objectId-specific:
  color: {},
  fillColor: {},
  shapeName: {},
  strokeWidth: {},
  strokeDasharray: {},
  fillOpacity: {},
  showTextInput: {},
  svgViewBoxWidth: {},
  svgViewBoxHeight: {},

  selectedMarking: {},  // This is also objectId specific
  draggedMarking: null, // This is used with pointer-type, not currently in use
  selectedMarkingHtmlAttributes: {},  // This is also objectId specific
  markingsCurrentlyUpdating: [],
  markingsCurrentlyDeleting: [],
  markingDeleteFailedList: [],
  markingSaveFailedList: [],
  markingDeleteFailedId: null,
  markingSaveFailedParams: null,
  lastOnShapeClickTime: null,
  mapModalLocation: null,
  textInputCoordinates: {},

  selectedTemplate: null,
  selectedFolderId: null,
  selectedStatus: null,

  // Keys must match old UI to make printing work (without messy mappings)
  // TODO: We could just send ids to BE? We already have calculated them in new UI...
  // But BE-code already exists, so don't shake the boat now...
  markingFilters: {
    selectedFolder: null,
    selectedstatus: null,
    selectedDate: null,
    startDate: null,
    endDate: null,
    ids: []
  },

  fullscreenMode: {},
  showInfoBoxes: false, // There is not info boxes in document, 1 value is enough  
  updateBoxPosition: 0,
  visibleStatusDropdownId: 0,  // Trick to make clicked infobox z-index greater than others. Select is then on top of others.

  keepShapeSelected: {},
  autoCreateMarkingContent: {},
  autoCreateMarking: {},
  autoCreateMarkingShape: {},
  drawSettings: {},
  drawSettingStatus: 'idle',
  drawingToolsOpenMenu: {},
  zoomTransform: {},
  zoomDelta: {},  // Trick to change zoom from upper component
  pinTemplate: {},
  resumeZoomTrigger: {},  // Trick to make zoom resume when needed
};

export const deleteAllMarkings = createAsyncThunk(
  'image/deleteAllMarkings',
  async (params) => {
    const response = await api.deleteAllMarkings(params);

    return response.body;
  }
)

export const deleteMarkingInterval = createAsyncThunk(
  'image/deleteMarkingInterval',
  async (params, { getState, dispatch }) => {
    const markingId = params.markingId;

    clearTimeout(retryDeleteTimeouts[markingId]);

    let marking = null;
    Object.values(getState().image.markings)?.forEach(markings => {
      if (!marking) {
        marking = markings?.find(m => markingIdMatch(m, markingId));
      }
    });
    if (!marking) {
      clearInterval(deleteIntervals[markingId]);
      deleteIntervals[markingId] = null;
      return;
    }

    if (deleteIntervals[markingId]) {
      return;
    }

    deleteIntervals[markingId] = setInterval(() => {
      marking = null;
      Object.values(getState().image.markings)?.forEach(markings => {
        if (!marking) {
          marking = markings?.find(m => markingIdMatch(m, markingId));
        }
      });
      let id = getMarkingId(marking);
      if (!isNaN(id) && !getState().image.markingsCurrentlyDeleting.includes(id)) {
        // This is already saved into database and has real id
        params.marking = marking;
        params.markingId = id;
        dispatch(deleteMarking(params));
      }
    }, 300);
  }
);

export const deleteMarking = createAsyncThunk(
  'image/deleteMarking',
  async (params) => {
    const markingId = params.markingId;

    clearInterval(deleteIntervals[params.marking.id]);
    deleteIntervals[params.marking.id] = null;
    clearInterval(deleteIntervals[params.marking.databaseId]);
    deleteIntervals[params.marking.databaseId] = null;

    /**
     * Create watcher before delete is started. We clear watcher-interval when delete is made.
     * If delete is taking too long we can show warnings in watcher.
     */
    let counter = 0;
    deleteWaits[markingId] = setInterval(() => {
      counter++;
      if (counter % 20 === 0) {
        /*title: this.props.intl.formatMessage(commonMessages.warning),
          message: this.props.intl.formatMessage({ id: 'object.markingsDeleteError3', defaultMessage: 'Deleting a marking is taking longer than expected. There may be a connection issue. The attempt to delete will continue...' }),
        ;*/
        deleteStatus[markingId] = 'warning';
      }
    }, 300);

    const response = await api.deleteMarking(params);

    return response.body
  }
);

export const saveMarkingInterval = createAsyncThunk(
  'image/saveMarkingInterval',
  async (params, { getState, dispatch }) => {
    const markingId = params.markingId;

    clearTimeout(retrySaveTimeouts[markingId]);

    /**
     * We need only 1 interval per marking.
     * In saveMarking() we take currently up-to-date svg object and save it.
     * So we need only 1 save request.
     */
    if (saveIntervals[markingId] || deleteIntervals[markingId]) {
      return;
    }

    saveIntervals[markingId] = setInterval(() => {
      if (!getState().image.markingsCurrentlyUpdating.includes(markingId)) {
        dispatch(saveMarking(params));
      }
    }, 300);
  }
);

// Save one marking
export const saveMarking = createAsyncThunk(
  'image/saveMarking',
  async (params, { getState }) => {
    const markingId = params.markingId;

    if (getState().image.markings[params.objectId]?.length === 0) {
      return;
    }

    clearInterval(saveIntervals[markingId]);
    saveIntervals[markingId] = null;

    const markingArray = [];
    getState().image.markings[params.objectId]?.forEach(m => {
      if (markingIdMatch(m, markingId)) {
        markingArray.push(m);
      }
    });

    if (markingArray.length === 0) {
      return;
    }

    /**
     * Create watcher before save is started. We clear watcher-interval when save is made.
     * If save is taking too long we can show warnings in watcher.
     */
    let counter = 0;
    saveWaits[markingId] = setInterval(() => {
      counter++;
      if (counter % 20 === 0) {
        /*
          title: this.props.intl.formatMessage(commonMessages.warning),
          message: this.props.intl.formatMessage({ id: 'object.markingsSaveError3', defaultMessage: 'Saving a marking is taking longer than expected. There may be a connection issue. The attempt to save will continue...' }),
        */
        // TODO: Push to toastSlice to make translations work. See errorTranslator

        saveStatus[markingId] = 'warning';
      }
    }, 300);

    // Add markingId to params to use recycle it in retry situations, in MarkingSaveBar
    const response = await api.saveMarking({ objectId: params.objectId, marking: markingArray[0], nodeId: params.nodeId, markingId: getMarkingId(markingArray[0]), documentId: params.documentId });

    return response.body;
  }
);

export const getMarkings = createAsyncThunk(
  'image/getMarkings',
  async (params) => {
    const response = await api.getMarkings(params);
    return response.body;
  }
);

export const getMarking = createAsyncThunk(
  'image/getMarking',
  async (params) => {
    const response = await api.getMarking(params);
    return response.body;
  }
);

export const createMarkingContent = createAsyncThunk(
  'image/createMarkingContent',
  async (params, { getState, dispatch, rejectWithValue }) => {
    try {
      var data = {
        marking_id: params?.markingId || getMarkingId(getState().image.selectedMarking[params.objectId]),
        description: params?.description,
        template_id: getState().image.selectedTemplate?.value || getState().doctemplates.templateList[0]?.enabled,
        parent_id: getState().image.selectedFolderId || 0,
        status: getState().image.selectedStatus?.value,
        createOnlyIfEmpty: params?.createOnlyIfEmpty
      };
      const response = await api.createMarkingContent(data);

      dispatch(objectsSlice.fetchObjectsChangedSinceLastFetch());

      if (response.body.content_node_id) {
        dispatch(documentSlice.setVisibleMarkingNodeIds([response.body.content_node_id]));
      } else {
        dispatch(documentSlice.setVisibleMarkingNodeIds(null));
      }

      // If right parent_id is already in response, don't overwrite it with wrong (0)
      if (!response.body.parent_id) {
        response.body.parent_id = data.parent_id;
      }

      return response.body;
    } catch (err) {
      dispatch(modalSlice.closeModal('markingContentModal'));
      return rejectWithValue(err?.message);
    }
  }
);

export const changeMarkingContentParent = createAsyncThunk(
  'image/changeMarkingContentParent',
  async (params, { dispatch, getState }) => {
    var data = {
      marking_id: getMarkingId(getState().image.selectedMarking[params.objectId]),
      parent_id: params.folderId
    };

    const response = await api.changeMarkingContentParent(data);

    dispatch(objectsSlice.fetchObjectsChangedSinceLastFetch());

    if (response.body.content_node_id) {
      dispatch(documentSlice.setVisibleMarkingNodeIds([response.body.content_node_id]));
    } else {
      dispatch(documentSlice.setVisibleMarkingNodeIds(null));
    }

    response.body.parent_id = data.parent_id;
    response.body.id = data.marking_id;

    return response.body;
  }
);

export const copyMarking = createAsyncThunk(
  'image/copyMarking',
  async (params, { dispatch }) => {
    const response = await api.copyMarking(params.markingId);

    dispatch(objectsSlice.fetchObjectsChangedSinceLastFetch());

    return response.body;
  }
);

export const getDrawSettings = createAsyncThunk(
  'image/getDrawSettings',
  async (params) => {
    // Currently supporting only floorPlan
    const response = await api.getFloorPlanDrawSettings(params.floorPlanId);

    return response.body;
  }
);

export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    setFullscreenMode: (state, action) => {
      state.fullscreenMode[action.payload.objectId] = action.payload.value;
    },
    setColor: (state, action) => {
      state.color[action.payload.objectId] = action.payload;
    },
    setFillColor: (state, action) => {
      state.fillColor[action.payload.objectId] = action.payload;
    },
    setFillOpacity: (state, action) => {
      state.fillOpacity[action.payload.objectId] = action.payload.value;
    },
    setShapeName: (state, action) => {
      state.shapeName[action.payload.objectId] = action.payload.value;

      if (action.payload.value && state.autoCreateMarkingContent[action.payload.objectId]) {
        state.autoCreateMarkingShape[action.payload.objectId] = action.payload.value;
      }
    },
    setShapeFromAutoCreateShape: (state, action) => {
      if (state.autoCreateMarkingContent[action.payload.objectId] 
        && state.autoCreateMarkingShape[action.payload.objectId]
        && state.keepShapeSelected[action.payload.objectId]
      ) {
        state.shapeName[action.payload.objectId] = state.autoCreateMarkingShape[action.payload.objectId];
      }
    },
    setSelectedMarking: (state, action) => {
      if (action.payload.checkClickTime) {
        /**
         * When ellipse is created inside rectangle, click for rectangle is fired right after mouseup.
         * Click is fired because in that case mouse is not inside ellipse on mouseup.
         */

        if (state.lastOnShapeClickTime && differenceInMilliseconds(Date.now(), state.lastOnShapeClickTime) < 700) {
          return;
        }
      }
      state.markings[action.payload.objectId]?.forEach(m => {
        if (markingIdsMatch(m, state.selectedMarking[action.payload.objectId]) && m.svg_object_latest) {
          m.svg_object = m.svg_object_latest;
        }
      });

      state.selectedMarking[action.payload.objectId] = action.payload.marking;
      if (!action.payload.marking) {
        state.selectedMarkingHtmlAttributes[action.payload.objectId] = {};
      }
    },
    setDraggedMarking: (state, action) => {
      state.draggedMarking = action.payload;
    },
    setSelectedMarkingAttribute: (state, action) => {
      if (state.selectedMarking[action.payload.objectId]) {
        state.selectedMarking[action.payload.objectId][action.payload.field] = action.payload.value;
      }
    },
    setSelectedMarkingHtmlAttribute: (state, action) => {
      if (!isValidValue(action.payload.value)) {
        return;
      }
      if (!state.selectedMarkingHtmlAttributes[action.payload.objectId]) {
        state.selectedMarkingHtmlAttributes[action.payload.objectId] = {};
      }
      state.selectedMarkingHtmlAttributes[action.payload.objectId][action.payload.field] = action.payload.value;
    },
    setStrokeWidth: (state, action) => {
      state.strokeWidth[action.payload.objectId] = action.payload.value;
    },
    setStrokeDasharray: (state, action) => {
      state.strokeDasharray[action.payload.objectId] = action.payload.value;
    },
    setShowTextInput: (state, action) => {
      state.showTextInput[action.payload.objectId] = action.payload.value;
    },
    setTextInputCoordinates: (state, action) => {
      state.textInputCoordinates[action.payload.objectId] = action.payload.value;
    },
    setSvgViewBoxWidth: (state, action) => {
      state.svgViewBoxWidth[action.payload.objectId] = action.payload.value;
    },
    setSvgViewBoxHeight: (state, action) => {
      state.svgViewBoxHeight[action.payload.objectId] = action.payload.value;
    },
    setLastOnShapeClickTime: (state, action) => {
      state.lastOnShapeClickTime = action.payload;
    },
    setMarkingSvgObject: (state, action) => {
      Object.values(state.markings)?.forEach(markings => {
        markings?.forEach(m => {
          if (markingIdMatch(m, action.payload.id)) {
            /**
             * In normal case don't modify svg_object, subjx stops working. 
             * Save value to another property. That property can be used in Lightbox to show updated values faster.
             */
            m.svg_object_latest = action.payload.svgObject;

            if (action.payload.setSvgObject) {
              // This is marking copy. New marking created in copy is not selected, we can set svg_object.
              // And we must set it to make new marking appear in right place after fullscreen toggle.
              m.svg_object = action.payload.svgObject;
            }
          }
        });
      });
    },
    setMarkingAttribute: (state, action) => {
      Object.values(state.markings)?.forEach(markings => {
        markings?.forEach(m => {
          if (markingIdMatch(m, action.payload.id)) {
            m[action.payload.field] = action.payload.value;
          }
        });
      });
    },
    clearSelectedMarking: (state, action) => {
      state.markings[action.payload.objectId]?.forEach(m => {
        if (markingIdsMatch(m, state.selectedMarking[action.payload.objectId]) && m.svg_object_latest) {
          m.svg_object = m.svg_object_latest;
        }
      });

      state.selectedMarking[action.payload.objectId] = null;
      state.selectedMarkingHtmlAttributes[action.payload.objectId] = {};
    },
    setMapModalLocation: (state, action) => {
      state.mapModalLocation = action.payload;
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload;
    },
    setSelectedFolderId: (state, action) => {
      state.selectedFolderId = action.payload;
    },
    setSelectedStatus: (state, action) => {
      state.selectedStatus = action.payload;
    },
    setMarkingFilters: (state, action) => {
      state.markingFilters[action.payload.key] = action.payload.value;
    },
    setPinTemplateAttribute: (state, action) => {
      // Make sure pinTemplate is object, otherwise crash will happen.
      // I don't exactly know when it is array, floor_plan.draw_settings (and PHP) has something to do with it.
      if (Array.isArray(state.pinTemplate)) {
        state.pinTemplate = {};
      }
      if (!state.pinTemplate[action.payload.objectId]) {
        state.pinTemplate[action.payload.objectId] = {};
      }
      state.pinTemplate[action.payload.objectId][action.payload.key] = action.payload.value;
    },
    setShowInfoBoxes: (state, action) => {
      state.showInfoBoxes = action.payload;
    },
    setUpdateBoxPosition: (state, action) => {
      state.updateBoxPosition = action.payload;
    },
    setVisibleStatusDropdownId: (state, action) => {
      state.visibleStatusDropdownId = action.payload;
    },
    setMarkingStatuses: (state, action) => {
      state.markingStatuses[action.payload.id] = action.payload.status;
    },
    setKeepShapeSelected: (state, action) => {
      state.keepShapeSelected[action.payload.objectId] = action.payload.value;
    },
    setAutoCreateMarkingContent: (state, action) => {
      state.autoCreateMarkingContent[action.payload.objectId] = action.payload.value;
    },
    setDrawingToolsOpenMenu: (state, action) => {
      state.drawingToolsOpenMenu[action.payload.objectId] = action.payload.value;
    },
    setZoomTransform: (state, action) => {
      state.zoomTransform[action.payload.objectId] = action.payload.value;
    },
    setZoomDelta: (state, action) => {
      state.zoomDelta[action.payload.objectId] = { value: action.payload.value, ts: new Date().getMilliseconds() };  // Use ts to make useEffect update
    },
    setResumeZoomTrigger: (state, action) => {
      state.resumeZoomTrigger[action.payload.objectId] = action.payload.value;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMarkings.pending, (state, action) => {
        state.markingStatus = 'pending';
        state.markings[action.meta.arg.objectId] = [];
      })
      .addCase(getMarkings.fulfilled, (state, action) => {
        state.markingStatus = 'idle';
        state.markings[action.meta.arg.objectId] = action.payload.results;
      })
      .addCase(getMarkings.rejected, (state) => {
        state.markingStatus = 'error';
      })
      .addCase(saveMarkingInterval.pending, (state, action) => {
        state.markingSaveFailedParams = null;
        state.updateBoxPosition = Math.random();

        if (action.meta.arg.marking?.id) {
          /**
           * This is new marking. Push it immediately to markings, it's not there yet.
           * In error-situations marking may already be in markings.
           * MarkingSaveBar calls saveMarkingInterval again.
           * Check to avoid duplicates.
           */
          const marking = state.markings[action.meta.arg.objectId]?.find(m => markingIdMatch(m, action.meta.arg.marking?.id));
          if (!marking) {
            if (!state.markings[action.meta.arg.objectId]) {
              state.markings[action.meta.arg.objectId] = [];
            }
            state.markings[action.meta.arg.objectId].push(action.meta.arg.marking);
            if (!state.keepShapeSelected[action.meta.arg.objectId]) {
              // User doesn't want new marking selected, he wants to draw new marking
              state.selectedMarking[action.meta.arg.objectId] = action.meta.arg.marking;
            }
          }

          /**
           * We have to clear partyfolder-filter to make this new marking visible.
           * We don't know if user will add marking content document and select parent folder for it.
           * And there may be "keepShapeSelected" setting on, so shape is not selected.
           */
          state.markingFilters.selectedFolder = null;
          state.selectedFolderId = null;
        }
      })
      .addCase(saveMarking.pending, (state, action) => {
        state.markingsCurrentlyUpdating.push(action.meta.arg.markingId || action.meta.arg.marking?.id);

        state.markingStatus = 'pending';
      })
      .addCase(saveMarking.fulfilled, (state, action) => {
        const markingId = action.meta.arg.markingId;

        state.markingStatus = 'idle';

        // action.payload.results[0] is not defined when marking is deleted just before save-try
        if (action.payload?.results[0]) {
          // This idsMatch is enough when we compare svg_id and set databaseId. This is then new marking.
          if (idsMatch(state.selectedMarking[action.meta.arg.objectId]?.id, action.payload.results[0]?.svg_id)) {
            state.selectedMarking[action.meta.arg.objectId].databaseId = action.payload.results[0].id;  // Cant set id to keep shape selected
            // Let id be "svg_id" to keep things simple. When databaseId is set, use it.
            state.selectedMarking[action.meta.arg.objectId].created_ts = action.payload.results[0].created_ts;
          }

          // Replace marking with NaN-id
          state.markings[action.meta.arg.objectId]?.forEach(m => {
            // This idsMatch is enough when we compare svg_id and set databaseId. This is then new marking.
            if (idsMatch(m.id, action.payload.results[0]?.svg_id)) {
              m.databaseId = action.payload.results[0].id;  // Cant set id to keep shape selected
              // Let id be "svg_id" to keep things simple. When databaseId is set, use it.
              m.created_ts = action.payload.results[0].created_ts;
            }
            if (markingIdsMatch(m, action.payload.results[0])) {
              m.modified_ts = action.payload.results[0].modified_ts;
            }
          });
        }

        clearInterval(saveWaits[markingId]);
        saveWaits[markingId] = null;
        state.markingSaveFailedList = state.markingSaveFailedList.filter(id => id !== markingId);
        state.markingsCurrentlyUpdating = state.markingsCurrentlyUpdating.filter(m => m !== markingId);

        if (saveStatus[markingId] && saveStatus[markingId] !== "success") {
          /* TODO: Generate success-toast */
          saveStatus[markingId] = 'success';
        }

        if (state.autoCreateMarkingContent[action.meta.arg.objectId]) {
          state.autoCreateMarking[action.meta.arg.objectId] = action.meta.arg.marking;
        }
      })
      .addCase(saveMarking.rejected, (state, action) => {
        const markingId = action.meta.arg.markingId;

        state.markingStatus = 'error';

        /* Error-toaster is generated automatically */

        saveStatus[markingId] = 'error';
        state.markingsCurrentlyUpdating = state.markingsCurrentlyUpdating.filter(m => m !== markingId);

        if (!state.markingSaveFailedList.includes(markingId)) {
          state.markingSaveFailedList.push(markingId);
        }

        state.markingSaveFailedParams = action.meta.arg;
      })
      .addCase(deleteMarkingInterval.pending, (state, action) => {
        const markingId = action.meta.arg.markingId;

        state.markingDeleteFailedId = null;
        /**
         * Setting markings to object may cause problems, because id changes on the fly.
         * Maybe safer to keep it as array.
         */
        for (const [objectId, markings] of Object.entries(state.markings)) {
          markings?.forEach(m => {
            if (markingIdMatch(m, markingId)) {
              state.selectedMarking[objectId] = null;
              m.deleted = true;
            }
          });
        }
      })
      .addCase(deleteMarking.pending, (state, action) => {
        const markingId = action.meta.arg.markingId;

        state.markingStatus = 'deleting';

        state.markingsCurrentlyDeleting.push(markingId);
      })
      .addCase(deleteMarking.fulfilled, (state, action) => {
        const markingId = action.meta.arg.markingId;

        state.markingStatus = 'idle';

        clearInterval(deleteWaits[markingId]);
        deleteWaits[markingId] = null;

        clearInterval(deleteIntervals[markingId]);
        deleteIntervals[markingId] = null;

        state.markingsCurrentlyDeleting = state.markingsCurrentlyDeleting.filter(m => m !== markingId);

        if (deleteStatus[markingId] && deleteStatus[markingId] !== "success") {
          /* TODO: Generate success-toast */
          deleteStatus[markingId] = 'success';
        }
        state.markingDeleteFailedList = state.markingDeleteFailedList.filter(id => id !== markingId);

        for (const [objectId, markings] of Object.entries(state.markings)) {
          state.markings[objectId] = markings.filter(m => !markingIdMatch(m, markingId));
        }
      })
      .addCase(deleteMarking.rejected, (state, action) => {
        const markingId = action.meta.arg.markingId;

        state.markingStatus = 'error';

        deleteStatus[markingId] = 'error';
        state.markingsCurrentlyDeleting = state.markingsCurrentlyDeleting.filter(m => m !== markingId);

        Object.values(state.markings)?.forEach(markings => {
          markings?.forEach(m => {
            if (markingIdMatch(m, markingId)) {
              m.deleted = false;
            }
          });
        });

        if (!state.markingDeleteFailedList.includes(markingId)) {
          state.markingDeleteFailedList.push(markingId);
        }

        state.markingDeleteFailedId = markingId;
      })
      .addCase(deleteAllMarkings.pending, (state) => {
        state.markingStatus = 'deletingAll';
      })
      .addCase(deleteAllMarkings.fulfilled, (state, action) => {
        state.markingStatus = 'idle';
        resetMarkingData(state, action.meta.arg.objectId);
      })
      .addCase(deleteAllMarkings.rejected, (state) => {
        state.markingStatus = 'error';
      })
      .addCase(createMarkingContent.pending, (state) => {
        state.markingStatus = 'creatingContent';
      })
      .addCase(createMarkingContent.fulfilled, (state, action) => {
        state.markingStatus = 'idle';
        updateMarkingDataFromPayload(state, action);
      })
      .addCase(createMarkingContent.rejected, (state) => {
        state.markingStatus = 'error';
      })
      .addCase(getMarking.pending, (state) => {
        state.markingStatus = 'loading';
      })
      .addCase(getMarking.fulfilled, (state, action) => {
        state.markingStatus = 'idle';
        updateMarkingDataFromPayload(state, action);
      })
      .addCase(getMarking.rejected, (state) => {
        state.markingStatus = 'error';
      })
      .addCase(changeMarkingContentParent.pending, (state) => {
        state.markingStatus = 'loading';
      })
      .addCase(changeMarkingContentParent.fulfilled, (state, action) => {
        state.markingStatus = 'idle';
        updateMarkingDataFromPayload(state, action);
      })
      .addCase(changeMarkingContentParent.rejected, (state) => {
        state.markingStatus = 'error';
      })
      .addCase(copyMarking.pending, (state, action) => {
        state.markingStatuses[Number(action.meta.arg.markingId)] = 'saving';
      })
      .addCase(copyMarking.fulfilled, (state, action) => {
        state.markingStatuses[Number(action.meta.arg.markingId)] = 'idle';
        state.markingStatuses[Number(action.payload.marking.id)] = 'copyReady';
        state.markings[action.meta.arg.objectId].push(action.payload.marking);
      })
      .addCase(copyMarking.rejected, (state, action) => {
        state.markingStatuses[Number(action.meta.arg.markingId)] = 'error';
      })
      .addCase(getDrawSettings.pending, (state) => {
        state.drawSettingStatus = 'loading';
      })
      .addCase(getDrawSettings.fulfilled, (state, action) => {
        if (action.payload.draw_settings) {
          for (const [key, value] of Object.entries(action.payload.draw_settings)) {
            if (!state[key]) {
              console.log('not fdound' + key);
            }
            state[key][action.meta.arg.objectId] = value;
          }
        }

        state.drawSettingStatus = 'ready';
        state.drawSettings[action.meta.arg.objectId] = action.payload.draw_settings;
      })
      .addCase(getDrawSettings.rejected, (state) => {
        state.drawSettingStatus = 'error';
      });
  },
});

export const {
  setFullscreenMode,
  setColor,
  setFillColor,
  setFillOpacity,
  setStrokeWidth,
  setStrokeDasharray,
  setShowTextInput,
  setTextInputCoordinates,
  setShapeName,
  setShapeFromAutoCreateShape,
  setSvgViewBoxWidth,
  setSvgViewBoxHeight,
  setLastOnShapeClickTime,
  setSelectedMarking,
  setDraggedMarking,
  setMarkingSvgObject,
  clearSelectedMarking,
  setMapModalLocation,
  setSelectedMarkingAttribute,
  setMarkingAttribute,
  setSelectedTemplate,
  setSelectedFolderId,
  setSelectedStatus,
  setMarkingFilters,
  setShowInfoBoxes,
  setUpdateBoxPosition,
  setVisibleStatusDropdownId,
  setMarkingStatuses,
  setKeepShapeSelected,
  setAutoCreateMarkingContent,
  setSelectedMarkingHtmlAttribute,
  setDrawingToolsOpenMenu,
  setZoomTransform,
  setPinTemplateAttribute,
  setZoomDelta,
  setResumeZoomTrigger
} = imageSlice.actions;

export const selectMarkings = (state, objectId) => state.image.markings[objectId] || [];
export const selectMarkingsExist = (state, objectId) => { return state.image.markings[objectId]?.length > 0 };
export const selectMarkingStatus = (state) => state.image.markingStatus;
export const selectMarkingStatusIsLoading = (state) => { return state.image.markingStatus === 'loading' || state.image.markingStatus === 'creatingContent' };

export const selectColor = (state, objectId) => state.image.color[objectId] || state.image.defaultValues.color;
export const selectFillColor = (state, objectId) => state.image.fillColor[objectId] || state.image.defaultValues.fillColor;
export const selectStrokeWidth = (state, objectId) => state.image.strokeWidth[objectId] || state.image.defaultValues.strokeWidth;
export const selectStrokeDasharray = (state, objectId) => state.image.strokeDasharray[objectId] || state.image.defaultValues.strokeDasharray;
export const selectFillOpacity = (state, objectId) => state.image.fillOpacity[objectId] || state.image.defaultValues.fillOpacity;

export const selectShapeName = (state, objectId) => state.image.shapeName[objectId] || state.image.defaultValues.shapeName;

export const selectSelectedMarkingHtmlAttribute = (state, attribute, objectId) => state.image.selectedMarkingHtmlAttributes[objectId]?.[attribute];

export const selectShowTextInput = (state, objectId) => state.image.showTextInput[objectId];
export const selectTextInputCoordinates = (state, objectId) => state.image.textInputCoordinates[objectId];
export const selectSvgViewBoxWidth = (state, objectId) => state.image.svgViewBoxWidth[objectId] || state.image.defaultValues.svgViewBoxWidth;
export const selectSvgViewBoxHeight = (state, objectId) => state.image.svgViewBoxHeight[objectId] || state.image.defaultValues.svgViewBoxHeight;
export const selectSvgRatio = (state, objectId) => {
  const height = state.image.svgViewBoxHeight[objectId] || state.image.defaultValues.svgViewBoxHeight;
  const width = state.image.svgViewBoxWidth[objectId] || state.image.defaultValues.svgViewBoxWidth;
  return (height + width) / 2 / 200;
}
export const selectLastOnShapeClickTime = (state) => state.image.lastOnShapeClickTime;
export const selectSelectedMarking = (state, objectId) => state.image.selectedMarking[objectId];
export const selectIsSelectedMarking = (state, objectId, marking) => markingIdsMatch(state.image.selectedMarking[objectId], marking);
export const selectSelectedMarkingExists = (state, objectId) => state.image.selectedMarking[objectId]?.id ? true : false;
export const selectDraggedMarking = (state) => state.image.draggedMarking;
export const selectSelectedMarkingContentDocumentId = (state, objectId) => state.image.selectedMarking[objectId]?.content_document_id;

/** 
 * Make sure markingContentModal is closed and marking create content is finished. Then we know we can return right documentId.
 * It is handy to keep this in 1 select to reduce unnecessary renders.
 */
export const selectContentDocumentIdWhenViewable = (state, objectId) => {
  if (state.modal.modalsOpen['markingContentModal']) {
    // Modal is open, we can't render document on lower-part of floor plan -page, problems arising.
    return false;
  }

  if (state.image.markingStatus === 'creatingContent') {
    // Creating of (possibly) new document is in progress. Return false until it is finished.
    return false;
  }

  return state.image.selectedMarking[objectId]?.content_document_id;
}

export const selectSelectedMarkingContentObject = (state, objectId) => state.objects.objectsMapById[state.image.selectedMarking[objectId]?.object_id];
export const selectMarkingsCurrentlyUpdating = (state) => state.image.markingsCurrentlyUpdating;
export const selectMarkingsCurrentlyDeleting = (state) => state.image.markingsCurrentlyDeleting;
export const selectMarkingDeleteFailedList = (state) => state.image.markingDeleteFailedList;
export const selectMarkingSaveFailedList = (state) => state.image.markingSaveFailedList;
export const selectMarkingDeleteFailedId = (state) => state.image.markingDeleteFailedId;
export const selectMarkingSaveFailedParams = (state) => state.image.markingSaveFailedParams;
export const selectMapModalLocation = (state) => state.image.mapModalLocation;

export const selectSelectedTemplate = (state) => state.image.selectedTemplate;
export const selectSelectedFolderId = (state) => state.image.selectedFolderId;
export const selectSelectedStatus = (state) => state.image.selectedStatus;

export const selectMarkingFilters = (state, key) => {
  if (!key) {
    return state.image.markingFilters;
  }

  return state.image.markingFilters[key];
}
export const selectMarkingFiltersInUse = (state) => {
  return state.image.markingFilters.selectedDate || state.image.markingFilters.selectedFolder || state.image.markingFilters.selectedStatus;
}
export const selectFullscreenMode = (state, objectId) => state.image.fullscreenMode[objectId];
export const selectShowInfoBoxes = (state) => state.image.showInfoBoxes;
export const selectMarking = (state, markingId, objectId) => {
  /**
   * If user is on document-page and he hasn't access to floor plan, image.markings is undefined.
   * We have to use document.markings then. Then marking is read-only.
   * 
   * Little hack: If markingId is not given, we want to get selectedMarking:
   * By this we can reduce renders
   */
  const mId = markingId || getMarkingId(state.image.selectedMarking[objectId]);
  return state.image.markings[objectId]?.find(m => markingIdMatch(m, mId)) || state.document.data?.markings?.find(m => markingIdMatch(m, mId));
}

export const selectUpdateBoxPosition = (state) => state.image.updateBoxPosition;
export const selectVisibleStatusDropdownId = (state) => state.image.visibleStatusDropdownId;

export const selectMarkingStatuses = (state, markingId) => state.image.markingStatuses[Number(markingId)] || 'idle';

/**
 * Have to use createSelector, otherwise this is ran after EVERY dispatch 
 * and Array.filter() always return new instance and causes re-render.
 * 
 * EDIT 26.4.23: Is there really any valid reason to keep selectedMarking visible? I guess not...
 */
export const selectVisibleMarkings = createSelector([
  selectMarkings,
  //selectSelectedMarking,
  state => selectMarkingFilters(state, 'ids'),
  state => selectMarkingFilters(state, 'selectedFolder'),
  state => selectMarkingFilters(state, 'selectedStatus'),
  state => selectMarkingFilters(state, 'selectedDate'),
  state => selectMarkingFilters(state, 'startDate'),
  state => selectMarkingFilters(state, 'endDate')
], (
  markings,
  //selectedMarking,
  markingFilterIds,
  selectedFolderId,
  selectedStatus,
  markingFilterDateString,
  markingFilterStartDate,
  markingFilterEndDate
) => {
  return markings?.filter(m => {
    if (markingFilterIds?.length) {
      return markingFilterIds.includes(Number(m.id)) || markingFilterIds.includes(Number(m.databaseId));
    }
    if (
      //markingIdsMatch(m, selectedMarking) ||
      (
        (
          !selectedFolderId ||
          Number(m.parent_id) === Number(selectedFolderId)
        ) &&
        (
          !selectedStatus ||
          m.status === selectedStatus ||
          (selectedStatus?.value === "unknown" && !m.status)
        ) &&
        (
          !markingFilterDateString ||
          dateHelper.isInDateRange(
            m.created_ts || m.document_created_ts,
            markingFilterDateString,
            { start: markingFilterStartDate * 1000, end: markingFilterEndDate * 1000 }
          )
        )
      )
    ) {
      return true;
    } else {
      return false;
    }
  });
});

export const selectMarkingsMatchFolderCount = createSelector([
  selectMarkings,
  (_state, _objectId, folderId) => folderId
], (
  markings,
  folderId
) => {
  return markings?.filter(m => folderId && Number(m.parent_id) === Number(folderId)).length;
});

export const selectMarkingsMatchStatusCount = createSelector([
  selectMarkings,
  (_state, _objectId, status) => status
], (
  markings,
  status
) => {
  return markings?.filter(m => status && m.status === status).length;
});

export const selectMarkingsMatchDateCount = createSelector([
  selectMarkings,
  (_state, _objectId, dateString) => dateString
], (
  markings,
  dateString
) => {
  return markings?.filter(m => {
    return dateHelper.isInDateRange(
      m.created_ts || m.document_created_ts,
      dateString,
      { start: null, end: null }
    )
  }).length;
});

export const selectKeepShapeSelected = (state, objectId) => state.image.keepShapeSelected[objectId] || false; // Has to be false, undefined causes "A component is changing an uncontrolled input to be controlled." warning.
export const selectAutoCreateMarkingContent = (state, objectId) => state.image.autoCreateMarkingContent[objectId] || false; // Has to be false, undefined causes "A component is changing an uncontrolled input to be controlled." warning.
export const selectAutoCreateMarking = (state, objectId) => state.image.autoCreateMarking[objectId];

export const selectDrawSettings = (state, objectId) => state.image.drawSettings[objectId];
export const selectDrawingToolsOpenMenu = (state, objectId) => state.image.drawingToolsOpenMenu[objectId];
export const selectZoomTransform = (state, objectId) => state.image.zoomTransform[objectId] || state.image.defaultValues.zoomTransform;
export const selectDrawSettingStatus = (state) => state.image.drawSettingStatus;
export const selectPinTemplate = (state, objectId) => state.image.pinTemplate[objectId];
export const selectZoomDelta = (state, objectId) => state.image.zoomDelta[objectId];

export const selectMarkingTs = (state, markingId) => {
  let marking = null
  for (const [, markings] of Object.entries(state.image.markings)) {
    marking = markings.find(m => markingIdMatch(m, markingId));
  }

  if (!marking) {
    // Marking not found. This is propably view-only (document shared, not floor plan) user on document-page.
    marking = state.document.data?.markings?.find(m => markingIdMatch(m, markingId));
  }
  return marking?.modified_ts || null;
}

export const selectResumeZoomTrigger = (state, objectId) => state.image.resumeZoomTrigger[objectId];

export default imageSlice.reducer;
