import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import * as api from './templatesEditorAPI';
import * as NodeTypes from './NodeTypes';
import * as NodeFunctions from './NodeFunctions';
import * as NodeProcessFunctions from './NodeProcessFunctions';
import { templateTagHelper } from '../../utils/templateTagHelpers';
import { selectSuperfolders } from '../superfolders/superfoldersSlice';

const defaultPrintSettings = {
  pagebreakOnSection: false,
  printCoverPage: false,
  printNamesAndTimestamps: true,
  printSignature: false,
  printTableOfContents: false,
  hideTemplateName: false,
  printMarkingsAndFloorPlan: true
};

const initialState = {
  listOrderField: 'created_ts',
  listOrderDirection: 'desc',
  // We are using createSelector to selectTemplates into list. It doesn't work if we use templateListById[id] structure.
  // Templates are rarely edited in the list. And we have react-list, so even when we edit template title and 25 rows
  // are re-rendered, it doesn't cause much harm. At least we can keep code clean and simple. I think it's more important.
  templateList: [],
  data: null,
  firstLevelNodeIds: [],
  nodesById: {},
  status: 'idle',
  fetchStatus: 'idle',
  listStatus: 'idle',
  movedNodeId: null,
  saveButtonEnabled: false,
  launchTemplateSave: false,
  launchTemplateCreate: false,
  nodeAddOpen: false,
  nodeEditOpen: false,
  clipboard: {
    copy: {
      data: {},
      nodeDepth: 0,
      deepestSectionLevel: 0,
      hasMacroSection: false
    },
    drag: { // This is used to cut node when dragging
      data: {},
      nodeDepth: 0,
      deepestSectionLevel: 0,
      hasMacroSection: false
    }
  },
  nodeColumnRowsModalData: {
    nodeId: null,
    columnIndex: null
  },
  editMode: true,
  draggedNodeId: null,
  moveToDocumentId: null,
  deleteReady: false,
  availableTags: [],
  tagsGlobalEditMode: false,
  searchTerm: '',
  templateListSidebarOpen: true,
  activeTagFilters: {},

  // Used to indicate where new node was created. 
  // There may be multiple macro-sections in document, so id and parent is not enough, we need whole path.
  // Path is built when node-components are rendered. There we know "real" path.
  createdNodePath: null,

  typeFilter: null,
  currentPrintSettings: defaultPrintSettings,
  savePrintSettingsStatus: 'idle',

  newFilesByNodeAndFileId: {},

  lightboxImageNodeId: null,

  geolocationEnabled: false,
  // Text areas
  // Same textnode can be multiple times in document (in macro, and macro is attached multiple times).
  // So nodeId is not unique, but path is. We want to keep only 1 textarea on edit mode at time.
  // Otherwise problems arise... (space and line breaks dont work)
  editingTextNodePath: null,
  textFieldStates: {},
  pastedNodeData: [],  // When we paste nodes from another document, collect data needed to copy files here
  copyDocumentId: null,  // Sent in "whole document copy" case to BE so we know if we have to copy all files from document
  requiredNodes: []
};

export const fetchOwnTemplates = createAsyncThunk(
  'templatesEditor/fetchOwnTemplates',
  async (params) => {
    const response = await api.fetchOwnTemplates(params);
    return response.body;
  }
);

export const fetchTemplate = createAsyncThunk(
  'templatesEditor/fetchTemplate',
  async (documentId, { dispatch }) => {
    const response = await api.fetchTemplate(documentId);

    dispatch(fetchAvailableTags())
    return response.body;
  }
);

export const fetchAvailableTags = createAsyncThunk(
  'templatesEditor/fetchAvailableTags',
  async () => {
    const response = await api.fetchAvailableTags();

    return response.body;
  }
);

export const saveTemplate = createAsyncThunk(
  'templatesEditor/saveTemplate',
  async (params, { getState }) => {

    const isRequiredNodesSet = getState().templatesEditor.requiredNodes.length > 0;

    const response = await api.saveTemplate(
      getState().templatesEditor.id,
      getState().templatesEditor.data,
      params.files,
      params.fileParams,
      getState().templatesEditor.pastedNodeData,
      isRequiredNodesSet
    );
    return response?.body;
  }
);

export const createTemplate = createAsyncThunk(
  'templatesEditor/createTemplate',
  async (params, { getState, dispatch }) => {
    const response = await api.createTemplate(
      getState().templatesEditor.data,
      getState().templatesEditor.copyDocumentId,
      params.files,
      params.fileParams,
      getState().templatesEditor.pastedNodeData
    );

    dispatch(fetchOwnTemplates());

    return response?.body;
  }
);

export const deleteTemplate = createAsyncThunk(
  'templatesEditor/deleteTemplate',
  async (_, { getState, dispatch }) => {
    const response = await api.deleteTemplate(getState().templatesEditor.id);

    dispatch(fetchOwnTemplates());

    return response?.body;
  }
)

export const toggleTemplateActive = createAsyncThunk(
  'templatesEditor/toggleTemplateActive',
  async (_, { getState }) => {
    const response = await api.toggleTemplateActive(getState().auth.data.company_id, getState().templatesEditor.id);

    return response?.body;
  }
)

export const saveTemplateOwner = createAsyncThunk(
  'templatesEditor/saveTemplateOwner',
  async (companyId, { getState, dispatch }) => {
    const response = await api.saveTemplateOwner(getState().templatesEditor.id, companyId);

    // Owner is changed, user probably doesn't have access to this template anymore
    dispatch(fetchOwnTemplates());

    return response?.body;
  }
)

export const saveTemplateTags = createAsyncThunk(
  'templatesEditor/saveTemplateTags',
  async (tags, { getState }) => {
    const response = await api.saveTemplateTags(getState().templatesEditor.id, tags, getState().templatesEditor.tagsGlobalEditMode);

    return response?.body;
  }
)

export const savePrintSettings = createAsyncThunk(
  'templatesEditor/savePrintSettings',
  async (_opts, { getState }) => {
    const response = await api.savePrintSettings(getState().templatesEditor.id, getState().templatesEditor.currentPrintSettings);

    return response.body;
  }
)

export const templatesEditorSlice = createSlice({
  name: 'templatesEditor',
  initialState,
  reducers: {
    setListOrderField: (state, action) => {
      state.listOrderField = action.payload;
    },
    setListOrderDirection: (state, action) => {
      state.listOrderDirection = action.payload;
    },
    resetTemplatesEditorData: (state) => {
      // Template list is not resetted, have to keep it even when we move to superfolders editor
      state.data = null;
      state.firstLevelNodeIds = [];
      state.nodesById = {};
      state.status = 'idle';
      state.fetchStatus = 'idle';
      state.listStatus = 'idle';
      state.movedNodeId = null;
      state.saveButtonEnabled = false;
      state.nodeAddOpen = false;
      state.nodeEditOpen = false;
      // Dont'reset state.clipboard.copy, it is used between documents
      state.clipboard.drag = { // This is used to cut node when dragging
        data: {},
        nodeDepth: 0,
        deepestSectionLevel: 0,
        hasMacroSection: false
      };
      state.nodeColumnRowsModalData = {
        nodeId: null,
        columnIndex: null
      };
      state.editMode = true;
      state.draggedNodeId = null;
      state.moveToDocumentId = null;
      state.deleteReady = false;
      state.availableTags = [];
      state.tagsGlobalEditMode = false;

      // Don't reset these, these are related to sidebar template list and are common for document and superfolder templates
      // Actually there could be separate slice for templatesEditors and documentTemplate...
      // state.searchTerm = '';
      // state.templateListSidebarOpen = true; 
      // state.activeTagFilters = {};
      // state.typeFilter = null;

      state.createdNodePath = null;

      state.currentPrintSettings = defaultPrintSettings;
      state.savePrintSettingsStatus = 'idle';

      state.newFilesNodeId = null;
      state.newFilesByFileId = {};

      state.lightboxImageNodeId = null;

      state.geolocationEnabled = false;
      // Text areas
      state.editingTextNodePath = null;
      state.textFieldStates = {};
      state.pastedNodeData = [];
      state.copyDocumentId = null;
      state.requiredNodes = [];
    },
    // Collapse/expand node
    collapseNode(state, action) {
      if (!state.nodesById) {
        return;
      }
      const node = state.nodesById[action.payload];
      if (!node) {
        return;
      }
      node.collapsed = 1;
    },
    expandNode(state, action) {
      if (!state.nodesById) {
        return;
      }
      const node = state.nodesById[action.payload];
      if (!node) {
        return;
      }
      node.collapsed = 0;
    },
    resetMovedNode(state, action) {
      if (!state.nodesById) {
        return;
      }
      const node = state.nodesById[action.payload];
      if (!node) {
        return;
      }

      // Reset wasMoved
      if (node.wasMoved !== undefined) {
        node.wasMoved = undefined;
      }
    },
    resetCreatedNode(state, action) {
      if (!state.nodesById) {
        return;
      }
      const node = state.nodesById[action.payload];
      if (!node) {
        return;
      }

      if (node.wasCreated !== undefined) {
        delete node.wasCreated;
        state.createdNodePath = null;
      }
    },
    // This is used to save node.label and node.title
    setNodeValue(state, action) {
      const id = action.payload.id;
      const field = action.payload.field;
      const value = action.payload.value;

      const nodesByIdNode = state.nodesById[id];
      if (nodesByIdNode) {
        nodesByIdNode[field] = value;

        if (nodesByIdNode.macroId && nodesByIdNode.parentNodeId && nodesByIdNode.type === 'section' && field === 'title') {
          // User edited macro-section section title. Update also macro-section.label. 
          // It's impossible for user to edit macro-section.label directly in UI. He also can't understand why there should be 2 titles.
          // We actually have to find all nodes with this macroId and update their labels.
          const macroSectionNodes = [];
          NodeFunctions.recursiveFindMacroSectionNodes(state.data, nodesByIdNode.macroId, macroSectionNodes);

          macroSectionNodes?.forEach((node) => {
            node.label = value;
          });
        }

        if (field === 'location') {
          // User set location for new image. Set same location for all new images in same input-images.
          // Logic works like this in normal document. It is frustrating to set same location for example for 10 images.
          const parentNode = state.nodesById[nodesByIdNode.parentNodeId];
          if (parentNode) {
            parentNode.childNodes.forEach((childNodeId) => {
              const childNodeById = state.nodesById[childNodeId];
              if (childNodeById && !childNodeById.filename) {
                // Filename not yet set, this is new file.
                const childNode = NodeFunctions.recursiveFindNode(state.data, childNodeId);
                childNode[field] = value;
                childNodeById[field] = value;
              }
            });
          }
        }
      }
      const node = NodeFunctions.recursiveFindNode(state.data, id);
      if (node) {
        node[field] = value;
      }

      state.saveButtonEnabled = true;
    },
    setNodeOptionValue(state, action) {
      if (state.nodesById[action.payload.id]) {
        state.nodesById[action.payload.id].options[action.payload.index] = action.payload.value;
      }
      const node = NodeFunctions.recursiveFindNode(state.data, action.payload.id);
      if (node) {
        node.options[action.payload.index] = action.payload.value;
      }
      state.saveButtonEnabled = true;
    },
    addNodeOption(state, action) {
      if (state.nodesById[action.payload.id]) {
        if (!state.nodesById[action.payload.id].options) {
          state.nodesById[action.payload.id].options = [];
        }
        state.nodesById[action.payload.id].options.push(action.payload.value);
      }
      const node = NodeFunctions.recursiveFindNode(state.data, action.payload.id);
      if (node) {
        if (!node.options) {
          node.options = [];
        }
        node.options.push(action.payload.value);
      }
      state.saveButtonEnabled = true;
    },
    reorderNodeOptions(state, action) {
      if (state.nodesById[action.payload.id]) {
        state.nodesById[action.payload.id].options = action.payload.options;
      }
      const node = NodeFunctions.recursiveFindNode(state.data, action.payload.id);
      if (node) {
        node.options = action.payload.options;
      }
      state.saveButtonEnabled = true;
    },
    deleteNodeOption(state, action) {
      if (state.nodesById[action.payload.id]) {
        state.nodesById[action.payload.id].options.splice(action.payload.index, 1);
      }
      const node = NodeFunctions.recursiveFindNode(state.data, action.payload.id);
      if (node) {
        node.options.splice(action.payload.index, 1);
      }
      state.saveButtonEnabled = true;
    },
    setNodeColumnValue(state, action) {
      if (state.nodesById[action.payload.id]) {
        state.nodesById[action.payload.id].columns[action.payload.index][action.payload.field] = action.payload.value;
      }
      const node = NodeFunctions.recursiveFindNode(state.data, action.payload.id);
      if (node) {
        node.columns[action.payload.index][action.payload.field] = action.payload.value;
      }
      state.saveButtonEnabled = true;
    },
    addNodeColumn(state, action) {
      if (state.nodesById[action.payload.id]) {
        if (!state.nodesById[action.payload.id].columns) {
          state.nodesById[action.payload.id].columns = [];
        }
        state.nodesById[action.payload.id].columns.push({ label: action.payload.value });
      }
      const node = NodeFunctions.recursiveFindNode(state.data, action.payload.id);
      if (node) {
        if (!node.columns) {
          node.columns = [];
        }
        // Add id to node column. Probably we don't need/use it, but in old UI there is column id when column has rows.
        node.columns.push({ label: action.payload.value, id: Math.random().toString(36).substring(2, 14) });
      }

      NodeFunctions.buildRowNodeData(node);

      state.saveButtonEnabled = true;
    },
    deleteNodeColumn(state, action) {
      if (state.nodesById[action.payload.id]) {
        state.nodesById[action.payload.id].columns.splice(action.payload.index, 1);
      }
      const node = NodeFunctions.recursiveFindNode(state.data, action.payload.id);
      if (node) {
        node.columns.splice(action.payload.index, 1);
      }

      NodeFunctions.buildRowNodeData(node);

      state.saveButtonEnabled = true;
    },
    setNodeColumnRowValue(state, action) {
      const id = action.payload.id;
      const columnIndex = action.payload.columnIndex;
      if (!id || columnIndex === undefined) {
        return;
      }

      if (state.nodesById[id]) {
        state.nodesById[id].columns[columnIndex].rows[action.payload.index][action.payload.field] = action.payload.value;
      }
      const node = NodeFunctions.recursiveFindNode(state.data, id);
      if (node) {
        node.columns[columnIndex].rows[action.payload.index][action.payload.field] = action.payload.value;
      }

      // Damn, data is duplicated into another place. We have to keeps node.rows up to date also...
      NodeFunctions.buildRowNodeData(node);

      state.saveButtonEnabled = true;
    },
    addNodeColumnRow(state, action) {
      const id = action.payload.id;
      const columnIndex = action.payload.columnIndex;
      if (!id || columnIndex === undefined) {
        console.error('Missing args in addNodeColumnRow');
        return;
      }

      if (state.nodesById[id]) {
        if (!state.nodesById[id].columns[columnIndex].rows) {
          state.nodesById[id].columns[columnIndex].rows = [];
        }
        state.nodesById[id].columns[columnIndex].rows.push({ label: action.payload.value });
      }
      const node = NodeFunctions.recursiveFindNode(state.data, id);
      if (node) {
        if (!node.columns[columnIndex].rows) {
          node.columns[columnIndex].rows = [];
        }
        node.columns[columnIndex].rows.push({ label: action.payload.value });
      }

      NodeFunctions.buildRowNodeData(node);

      state.saveButtonEnabled = true;
    },
    deleteNodeColumnRow(state, action) {
      const id = action.payload.id;
      const columnIndex = action.payload.columnIndex;
      const rowIndex = action.payload.index;
      if (!id || columnIndex === undefined || rowIndex === undefined) {
        console.error('deleteNodeColumnRow: invalid arguments');
        return;
      }

      if (state.nodesById[id]) {
        state.nodesById[id].columns[columnIndex].rows.splice(rowIndex, 1);
      }
      const node = NodeFunctions.recursiveFindNode(state.data, id);
      if (node) {
        node.columns[columnIndex].rows.splice(rowIndex, 1);
      }

      NodeFunctions.buildRowNodeData(node);

      state.saveButtonEnabled = true;
    },
    setNodeAddOpen(state, action) {
      state.nodeAddOpen = {};
      if (action.payload.openId && action.payload.value) {
        state.nodeAddOpen[action.payload.openId] = action.payload.value;
      }
    },
    setNodeEditOpen(state, action) {
      state.nodeEditOpen = {};
      if (action.payload.openId && action.payload.value) {
        state.nodeEditOpen[action.payload.openId] = action.payload.value;
      }
    },
    // This is used to copy and cut nodes. Cut is made by when node is dragged to new position.
    // When section of macro-section is cutted, we actually must cut macro-section. 
    // action.payload.id is then macro-section id.
    setNodeToClipboard(state, action) {
      const id = action.payload.id;
      const key = action.payload.key; // 'copy' or 'drag'
      const node = NodeFunctions.recursiveFindNode(state.data, id);
      const nodeWithVariables = state.nodesById[id]; // We want nodeLevel from here

      if (!node || !nodeWithVariables || !key) {
        console.error('setNodeToClipboard: invalid arguments');
        return;
      }

      if (!state.clipboard[key]) {
        state.clipboard[key] = {};
      }

      state.clipboard[key].data = JSON.parse(JSON.stringify(node));
      state.clipboard[key].nodeHelperVariables = {
        nodeLevel: nodeWithVariables.nodeLevel,
        parentNodeId: nodeWithVariables.parentNodeId
      };

      const collectedData = NodeFunctions.collectNodeCopyData(node);

      state.clipboard[key].nodeLevel = collectedData.maxLevel;
      state.clipboard[key].deepestSectionLevel = collectedData.deepestSectionLevel;
      state.clipboard[key].hasMacroSection = collectedData.hasMacroSection;
      if (collectedData.tooDeep) {
        state.clipboard[key].tooDeep = true;
      }
      else {
        state.clipboard[key].tooDeep = false;
      }

      state.clipboard[key].documentId = state.id; // We need so we know where from to copy files
    },
    resetClipboard(state, action) {
      const key = action.payload.key; // 'copy' or 'drag'
      state.clipboard[key] = {
        data: {},
        nodeDepth: 0,
        deepestSectionLevel: 0,
        hasMacroSection: false
      }
    },
    createMacroFromSection(state, action) {
      const id = action.payload.id;
      const node = state.nodesById[id];
      const uniq = Math.random().toString(36).substring(2, 14);
      const macroSectionSection = NodeFunctions.recursiveFindNode(state.data, id);
      const nodeTitle = node.title;

      const newMacro = {
        id: uniq,
        node: JSON.parse(JSON.stringify(macroSectionSection))
      };

      if (!state.data.macros) {
        state.data.macros = {};
      }

      if (Object.keys(state.data.macros).length === 0 && state.data.is_marking_template) {
        newMacro.node.isMarkingSection = true;
      }
      newMacro.node.macroId = uniq;

      newMacro.node.canEditTitle = true;
      newMacro.node.canRemove = true;

      state.data.macros[uniq] = JSON.parse(JSON.stringify(newMacro));

      // Macro is now created, now create node pointing to it.
      action.payload.macroId = uniq;
      action.payload.type = 'macro-section';
      action.payload.parentId = node.parentNodeId;

      NodeFunctions.deleteNode(state, action);
      const newNodeId = NodeFunctions.addNode(state, action);

      // Set macro-section label from old section title
      const newNode = NodeFunctions.recursiveFindNode(state.data, newNodeId);
      if (newNode) {
        newNode.label = nodeTitle;
      }
      state.nodesById[newNodeId].label = nodeTitle;
    },
    createMacro(state, action) {
      const uniq = Math.random().toString(36).substring(2, 14);
      const uniq2 = Math.random().toString(36).substring(2, 14);
      const macroSectionSection = {
        id: uniq2,
        macroId: uniq,
        type: 'section',
        nodes: [],
        canEditTitle: true,
        canRemove: true
      };

      const newMacro = {
        id: uniq,
        node: JSON.parse(JSON.stringify(macroSectionSection))
      };

      if (!state.data.macros) {
        state.data.macros = {};
      }

      if (Object.keys(state.data.macros).length === 0 && state.data.is_marking_template) {
        newMacro.node.isMarkingSection = true;
      }

      state.data.macros[uniq] = JSON.parse(JSON.stringify(newMacro));

      if (!state.nodesById[uniq2]) {
        state.nodesById[uniq2] = {};
      }
      state.nodesById[uniq2].wasCreated = true;

      // Macro is now created, now create node pointing to it.
      action.payload.macroId = uniq;
      action.payload.type = 'macro-section';

      NodeFunctions.addNode(state, action);
    },
    moveDraggedNodeTo(state, action) {
      if (!state.clipboard?.drag?.data?.id) {
        // This is not error, there may be new images in saveCtx and setting clipboard.drag is disabled.
        return;
      }

      action.payload.copyNodeId = state.clipboard?.drag?.data?.id;
      action.payload.id = state.clipboard?.drag?.data?.id;

      if (action.payload.imageMove) {
        // Image move is different to other cases, there we don't have "index + 1" in add-button.
        // If target index is greater than current index, we have to increment it with 1.
        // Because if we move image from index 0 => 1 and delete old image from 0, image is still at index 0.
        const node = state.nodesById[action.payload.id];
        const parentNode = NodeFunctions.recursiveFindNode(state.data, node.parentNodeId) || state.data;
        if (!parentNode || !parentNode.nodes) {
          console.error('moveDraggedNodeTo: parentNode not found or it hasnt nodes.');
          return;
        }

        const index = parentNode.nodes.findIndex((n) => n.id === node.id);
        if (index < 0) {
          console.error('moveDraggedNodeTo: current index not found.');
          return;
        }
        if (action.payload.parentId === node.parentNodeId) {
          if (index === action.payload.index) {
            // Don't move to current index in this case, it causes problems.
            return;
          } else if (index < action.payload.index) {
            action.payload.index++;
          }
        }
      }

      // Don't set new id, try to keep id-key-links working
      NodeFunctions.moveNode(state, action);

      if (!action.payload.imageMove) {
        state.clipboard.drag = {
          data: {},
          nodeDepth: 0,
          deepestSectionLevel: 0,
          hasMacroSection: false
        };
      }
    },
    // This is same than moveDraggedNodeTo, we just dont use clipboard. Give ids straight in payload.
    // This is only used with imageNodes.
    // action.payload = { id, copyNodeId }
    moveNodeTo(state, action) {
      // If target index is greater than current index, we have to increment it with 1.
      // Because if we move image from index 0 => 1 and delete old image from 0, image is still at index 0.
      const id = action.payload.id;
      const parentId = action.payload.parentId;
      let index = action.payload.index;

      const node = state.nodesById[id];
      const parentNode = NodeFunctions.recursiveFindNode(state.data, node.parentNodeId) || state.data;
      if (!parentNode || !parentNode.nodes) {
        console.error('moveDraggedNodeTo: parentNode not found or it hasnt nodes.');
        return;
      }

      const currentIndex = parentNode.nodes.findIndex((n) => n.id === node.id);
      if (currentIndex < 0) {
        console.error('moveDraggedNodeTo: current index not found.');
        return;
      }

      if (node.parentNodeId === parentId) {
        if (currentIndex === index) {
          // Don't move to current index in this case, it causes problems.
          return;
        } else if (currentIndex < index) {
          action.payload.index++; // Increment action.payload.index, not index. sction.payload is used in addNode
        }
      }

      const newNodeId = NodeFunctions.addNode(state, action);
      if (state.lightboxImageNodeId && node.type === 'image') {
        state.lightboxImageNodeId = newNodeId;
      }

      // Delete after add works because we create new id for moved node
      NodeFunctions.deleteNode(state, action);
    },
    addNode(state, action) {
      const newNodeId = NodeFunctions.addNode(state, action);

      if (action.payload.copyNode && action.payload.sourceDocumentId !== state.id) {
        state.pastedNodeData.push({
          sourceDocumentId: action.payload.sourceDocumentId,
          sourceNodeId: action.payload.copyNode.id,
          targetNodeId: newNodeId,
        });
      }
    },
    replaceNode(state, action) {
      // List parameters on top to make this clearer
      const id = action.payload.id;
      const copyNodeId = action.payload.copyNodeId;
      let copyNode = action.payload.copyNode;
      const macroId = action.payload.macroId;
      const index = action.payload.index;
      const sourceDocumentId = action.payload.sourceDocumentId;

      if (!id || (!copyNodeId && !copyNode) || index === undefined) {
        console.error('replaceNode: id, copyNodeId or index not defined');
        return;
      }

      const node = state.nodesById[id];
      const parentNode = NodeFunctions.recursiveFindNode(state.data, node?.parentNodeId) || state.data;

      if (!node || !parentNode) {
        console.error('replaceNode: missing node or parentNodes');
        return;
      }

      if (!copyNode) {
        copyNode = NodeFunctions.recursiveFindNode(state.data, copyNodeId);
      }

      if (!copyNode) {
        console.error('replaceNode: copyNode not found');
        return;
      }

      const uniq = Math.random().toString(36).substring(2, 14);
      const newNode = JSON.parse(JSON.stringify(copyNode));
      newNode.id = uniq;

      // Node was pasted, need to update all childNode ids also to prevent duplicate ids if node with same children
      // is pasted multiple times.
      NodeFunctions.updateChildNodeIds(newNode);

      if (sourceDocumentId !== state.id) {
        state.pastedNodeData.push({
          sourceDocumentId,
          sourceNodeId: copyNode.id,
          targetNodeId: newNode.id,
        });
      }

      if (macroId) {
        newNode.macroId = macroId;
      }

      if (parentNode.type === 'macro-section' && node?.type === 'section') {
        // If we replace macro-section section, we set node, not nodes
        // Actually if parent type is macro-section this can't be anything else than section. But let's check it anyway.
        if (parentNode.macroId) {
          // This is macro-section inside another macro-section (or section). We must find original macro-section from macros-root
          const macro = state.data.macros[parentNode.macroId];
          if (!macro) {
            return; // Shouldn't happen
          }
          macro.node = newNode;
        } else {
          // Do we ever get here? Maybe if macros are in right order and loop finds macro first from macros-root
          //parentNodeForSaving.node = newNode;
          console.error('replaceNode: if-branch were we shouldnt end');
        }
        parentNode.nodes = [];  // Clear nodes to make them populate again
      } else {
        parentNode.nodes.splice(index, 1, JSON.parse(JSON.stringify(newNode))); // .childNodes is based on .nodes in process-functions
      }

      state.nodesById[newNode.id] = JSON.parse(JSON.stringify(newNode));
      state.nodesById[newNode.id].wasCreated = true;

      // We deleted(replaced) existing node, process all again to set nodesById right
      NodeProcessFunctions.processNodeStructure(state);

      state.saveButtonEnabled = true;
      state.nodeAddOpen = {};
    },
    deleteNode(state, action) {
      const node = state.nodesById[action.payload.id];
      if (node?.type === 'image' && node?.parentNodeId) {
        // Image deleted, set lightbox to show next image

        // Find the parent of the deleted node
        const parentNode = NodeFunctions.recursiveFindNode(state.data, node.parentNodeId);

        // Helpers for finding the image, which is going to be shown next
        let deletedNodeFound = false;
        let nextImageToBeShown = null;

        for (const childNode of parentNode.nodes) {
          if (deletedNodeFound && !nextImageToBeShown) {
            nextImageToBeShown = childNode.id;
          }
          if (childNode.id === action.payload.id) {
            deletedNodeFound = true;
          }
        }

        // Set the next image to be shown
        if (!nextImageToBeShown && parentNode.nodes.length > 1) {
          // Last (but not the only one) was deleted, show node before last one
          // The deleted node is still last node here
          nextImageToBeShown = parentNode.nodes[parentNode.nodes.length - 2].id;
        }
        state.lightboxImageNodeId = nextImageToBeShown;
      }

      NodeFunctions.deleteNode(state, action);
    },
    deleteMacro(state, action) {
      const macroId = action.payload.macroId;
      const macro = state.data.macros[macroId];
      if (!macro) {
        return;
      }

      const macroSectionNodes = [];
      NodeFunctions.recursiveFindMacroSectionNodes(state.data, macroId, macroSectionNodes);

      macroSectionNodes?.forEach((node) => {
        NodeFunctions.deleteNode(state, { payload: { id: node.id } });
      });

      delete state.data.macros[macroId];
    },
    changeNodeType(state, action) {
      const node = NodeFunctions.recursiveFindNode(state.data, action.payload.id);
      var label = node.label || node.title || node.text;

      if (NodeTypes.typesWithNodes.includes(action.payload.type)) {
        node.nodes = [];
      }
      if (NodeTypes.typesWithoutNodes.includes(action.payload.type)) {
        delete node.nodes;
      }
      if (NodeTypes.typesWithText.includes(action.payload.type)) {
        node.text = label;
      }
      if (NodeTypes.typesWithLabel.includes(action.payload.type)) {
        node.label = label;
      }
      if (NodeTypes.typesWithTitle.includes(action.payload.type)) {
        node.title = label;
      }
      if (!NodeTypes.typesWithOptions.includes(action.payload.type)) {
        delete node.options;
      }
      if (!NodeTypes.typesWithColumns.includes(action.payload.type)) {
        delete node.columns;
      } else {
        if (!node.columns) {
          node.columns = [];
        }
      }
      if (!NodeTypes.typesWithValue.includes(action.payload.type)) {
        // Type changed to other than text, delete default value. It is not visible on template-editor, dangerous to leave it into data
        delete node.value;
        delete state.textFieldStates[node.id];
      } else {
        // Type changed to text, delete other default values than default text (=delete value.isChecked)
        if (node.value) {
          delete node.value.isChecked;
        }

        if (node.value && !Object.keys(node.value).length) {
          // node.value is {}. It is converted to [] in PHP. It causes problems when we try to set properties into it later.
          // So we have to delete empty object.
          delete node.value;
        }
      }

      if (action.payload.type === 'macro-section') {
        node.macroId = action.payload.macroId;
        node.label = state.data.macros[node.macroId].node.title;
      }

      if (action.payload.type !== 'macro-section' && action.payload.type !== 'section') {
        // This can only be used with macro-section and section
        delete node.canEditTitle;
        delete node.canRemove;
      }

      node.type = action.payload.type;
      if (node.parentNodeId) {
        NodeProcessFunctions.processNodeStructuresPartially(state, node);
      } else {
        NodeProcessFunctions.processNodeStructure(state);
      }

      state.saveButtonEnabled = true;
    },
    setNodeColumnRowsModalData(state, action) {
      const node = state.nodesById[action.payload.id];
      const column = node?.columns[action.payload.columnIndex];
      state.nodeColumnRowsModalData = action.payload;
      state.nodeColumnRowsModalData.columnLabel = column?.label;
    },
    setEditMode(state, action) {
      state.editMode = action.payload;
    },
    initTemplateData(state) {
      state.id = null;
      if (!state.copyDocumentId) {
        state.data = {
          title: '',
          active: false,
          nodes: [],
          macros: {}
        };
        state.firstLevelNodeIds = [];
        state.nodesById = {};
        //state.saveButtonEnabled = false; // When we want to disable "are you sure you want to move" confirm?
        state.nodeAddOpen = {};
        state.nodeEditOpen = {};

        state.nodeColumnRowsModalData = {
          nodeId: null,
          columnIndex: null
        };
        state.editMode = true;
        state.draggedNodeId = null;
        state.availableTags = [];
        state.tagsGlobalEditMode = false;
      }
      state.saveButtonEnabled = true;
    },
    setTemplateTitle(state, action) {
      if (!state.data) {
        state.data = {};
      }
      state.data.title = action.payload;
      state.saveButtonEnabled = true;
    },
    setTemplateCoverImage(state, action) {
      state.data.cover_image = action.payload;
      state.saveButtonEnabled = true;
    },
    toggleTagsGlobalEditMode(state) {
      state.tagsGlobalEditMode = !state.tagsGlobalEditMode;
    },
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    setTemplateListSidebarOpen(state, action) {
      state.templateListSidebarOpen = action.payload;
    },
    setActiveTagFilters(state, action) {
      state.activeTagFilters = action.payload;
    },
    setTypeFilter(state, action) {
      state.typeFilter = action.payload;
    },
    setCurrentPrintSettings(state) {
      state.currentPrintSettings = state.data.printSettings || defaultPrintSettings;
    },
    setPrintSettingValue(state, action) {
      state.currentPrintSettings[action.payload.key] = action.payload.value;
    },
    setCustomPrintSettingValue(state, action) {
      // I guess when we use customOptions, it always exist. But do this safety set anyway.
      if (!state.currentPrintSettings.customOptions) {
        state.currentPrintSettings.customOptions = {};
      }

      state.currentPrintSettings.customOptions[action.payload.key] = action.payload.value;
    },
    // Adding files/images related
    addNewFileByNodeAndFileId(state, action) {
      const parentNode = NodeFunctions.recursiveFindNode(state.data, action.payload.parentId);
      if (!parentNode) {
        console.error('addNewFileByNodeAndFileId: missing parent');
        return;
      }
      action.payload.type = parentNode?.type === 'input-images' ? 'image' : 'file';
      const newNodeId = NodeFunctions.addNode(state, action);

      if (!state.newFilesByNodeAndFileId[newNodeId]) {
        state.newFilesByNodeAndFileId[newNodeId] = {};
      }
      state.newFilesByNodeAndFileId[newNodeId][action.payload.fileId] = { id: action.payload.fileId, data: action.payload.data };
    },
    removeNewFileByFileId(state, action) {
      // TODO: Possible to remove by nodeId and fileId?
      if (state.newFilesByNodeAndFileId[action.payload]) {
        delete state.newFilesByNodeAndFileId[action.payload]
      }
    },
    markNewFileCompressed(state, action) {
      state.newFilesByNodeAndFileId[action.payload.nodeId][action.payload.fileId].compressed = true;
    },
    resetNewFiles(state) {
      state.newFilesByNodeAndFileId = {};
    },
    setLightboxImageNodeId: (state, action) => {
      state.lightboxImageNodeId = action.payload;
    },
    setFileGroupCollapse(state, action) {
      state.nodesById[action.payload.parentNodeId].fileGroupsByFilename[action.payload.filename].collapsed = !!action.payload.collapsed;
    },
    setGeolocationEnabled(state, action) {
      state.geolocationEnabled = action.payload;
    },
    launchTemplateSave(state) {
      state.launchTemplateSave = true;
    },
    launchTemplateCreate(state) {
      state.launchTemplateCreate = true;
    },
    setEditingTextNodePath(state, action) {
      state.editingTextNodePath = action.payload;
    },
    setTextFieldState(state, action) {
      state.textFieldStates[action.payload.nodeId] = {
        ...state.textFieldStates[action.payload.nodeId],
        ...action.payload.state
      }
    },
    setCopyDocumentId(state, action) {
      state.copyDocumentId = action.payload;
      state.saveButtonEnabled = false;
    },
    toggleTemplateCheckbox(state, action) {
      // Used to toggle "compilation template" and "marking template".
      // Toggling of these used to auto save whole template, but it is hazardous and strange for user.
      // All other things require save-button press, why wouldn't this? This autosave also accidentally
      // saved changes to node structure.
      state.data[action.payload] = state.data[action.payload] ? 0 : 1;
      state.saveButtonEnabled = true;
    },
    incrementRequiredNodes(state, action) {
      state.requiredNodes.push(action.payload);
    },
    decrementRequiredNodes(state, action) {
      state.requiredNodes = state.requiredNodes.filter((nodeId) => nodeId !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOwnTemplates.pending, (state) => {
        state.listStatus = 'loading';
      })
      .addCase(fetchOwnTemplates.fulfilled, (state, action) => {
        state.listStatus = 'idle';
        state.templateList = action.payload.results;
      })
      .addCase(fetchOwnTemplates.rejected, (state) => {
        state.listStatus = 'error';
      })
      .addCase(fetchTemplate.pending, (state) => {
        state.fetchStatus = 'loading';
        state.moveToDocumentId = null;
        state.saveButtonEnabled = false;
      })
      .addCase(fetchTemplate.fulfilled, (state, action) => {
        state.fetchStatus = 'idle';
        state.data = JSON.parse(JSON.stringify(action.payload));
        if (Array.isArray(state.data.macros)) {
          state.data.macros = {};
        }
        state.id = action.payload.id;
        NodeProcessFunctions.processNodeStructure(state);
      })
      .addCase(fetchTemplate.rejected, (state) => {
        state.fetchStatus = 'error';
      })
      .addCase(saveTemplate.pending, (state) => {
        state.status = 'saving';
      })
      .addCase(saveTemplate.fulfilled, (state, action) => {
        state.status = 'idle';
        state.saveButtonEnabled = false;
        state.launchTemplateSave = false;

        const template = state.templateList.find(t => t.id === action.payload.id);
        if (template) {
          template.title = action.payload.title;
        }

        state.data.modified_ts = action.payload.modified_ts;
        state.data.modifier_id = action.payload.modifier_id;

        // Set uploaded filenames (and other updated data) to nodes
        const nodeIds = action?.payload?.updatedNodeIds;
        if (!nodeIds) {
          return;
        }

        NodeProcessFunctions.processUpdatedNodeIds(state, nodeIds, action.payload);

        state.pastedNodeData = [];
      })
      .addCase(saveTemplate.rejected, (state) => {
        state.status = 'error';
        state.launchTemplateSave = false;
      })
      .addCase(createTemplate.pending, (state) => {
        state.status = 'saving';
      })
      .addCase(createTemplate.fulfilled, (state, action) => {
        state.status = 'idle';
        state.saveButtonEnabled = false;
        state.moveToDocumentId = action.payload.id;
        state.copyDocumentId = null;
        state.launchTemplateCreate = false;

        // Set uploaded filenames (and other updated data) to nodes
        const nodeIds = action?.payload?.updatedNodeIds;
        if (!nodeIds) {
          return;
        }

        NodeProcessFunctions.processUpdatedNodeIds(state, nodeIds, action.payload);

        state.pastedNodeData = [];
      })
      .addCase(createTemplate.rejected, (state) => {
        state.status = 'error';
        state.launchTemplateCreate = false;
      })
      .addCase(deleteTemplate.pending, (state) => {
        state.status = 'deleting';
      })
      .addCase(deleteTemplate.fulfilled, (state) => {
        state.status = 'idle';
        state.saveButtonEnabled = false;
        state.deleteReady = true;
      })
      .addCase(deleteTemplate.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(toggleTemplateActive.pending, (state) => {
        state.status = 'saving';
        state.data.active = !state.data.active;
      })
      .addCase(toggleTemplateActive.fulfilled, (state, action) => {
        state.status = 'idle';

        const template = state.templateList.find(t => t.id === state.id);
        if (template) {
          template.active = action.payload.active;
        }

        state.data.modified_ts = action.payload.modified_ts;
        state.data.modifier_id = action.payload.modifier_id;
      })
      .addCase(toggleTemplateActive.rejected, (state) => {
        state.status = 'error';
        state.data.active = !state.data.active;
      })
      .addCase(saveTemplateOwner.pending, (state, action) => {
        state.status = 'saving';
        state.data.company_id = action.meta.arg;
      })
      .addCase(saveTemplateOwner.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data.modified_ts = action.payload.modified_ts;
        state.data.modifier_id = action.payload.modifier_id;
      })
      .addCase(saveTemplateOwner.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(fetchAvailableTags.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAvailableTags.fulfilled, (state, action) => {
        state.status = 'idle';
        state.availableTags = action.payload.tags;
      })
      .addCase(fetchAvailableTags.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(saveTemplateTags.pending, (state) => {
        state.status = 'saving';
      })
      .addCase(saveTemplateTags.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data.tags = action.payload.tags;
        state.data.modified_ts = action.payload.document.modified_ts;
        state.data.modifier_id = action.payload.document.modifier_id;

        // Update tags in template list to make them appear in tag-filters
        const template = state.templateList.find(t => t.id === state.id);
        if (template) {
          template.tags = action.payload.tags.map(t => t.name);
        }
      })
      .addCase(saveTemplateTags.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(savePrintSettings.pending, (state) => {
        state.savePrintSettingsStatus = 'loading';
      })
      .addCase(savePrintSettings.fulfilled, (state) => {
        state.savePrintSettingsStatus = 'idle';
        state.data.printSettings = state.currentPrintSettings;
      })
      .addCase(savePrintSettings.rejected, (state) => {
        state.savePrintSettingsStatus = 'error';
      });
  },
});

export const {
  setListOrderField,
  setListOrderDirection,
  resetTemplatesEditorData,
  collapseNode,
  expandNode,
  // Template specific:
  setNodeValue,
  setNodeAddOpen,
  setNodeEditOpen,
  createMacro,
  createMacroFromSection,
  addNode,
  replaceNode,
  deleteNode,
  deleteMacro,
  resetMovedNode,
  resetCreatedNode,
  setNodeToClipboard,
  resetClipboard,
  changeNodeType,
  setNodeOptionValue,
  addNodeOption,
  reorderNodeOptions,
  deleteNodeOption,
  setNodeColumnValue,
  addNodeColumn,
  deleteNodeColumn,
  setNodeColumnRowValue,
  addNodeColumnRow,
  deleteNodeColumnRow,
  setNodeColumnRowsModalData,
  setEditMode,
  setDraggedNodeId,
  moveDraggedNodeTo,
  moveNodeTo,
  initTemplateData,
  setTemplateTitle,
  setTemplateCoverImage,
  toggleTagsGlobalEditMode,
  setSearchTerm,
  setTemplateListSidebarOpen,
  setActiveTagFilters,
  setTypeFilter,
  setCurrentPrintSettings,
  setPrintSettingValue,
  setCustomPrintSettingValue,
  setNewFilesNodeId,
  addNewFileByNodeAndFileId,
  removeNewFileByFileId,
  markNewFileCompressed,
  resetNewFiles,
  setLightboxImageNodeId,
  setFileGroupCollapse,
  setGeolocationEnabled,
  launchTemplateSave,
  launchTemplateCreate,
  setEditingTextNodePath,
  setTextFieldState,
  setCopyDocumentId,
  toggleTemplateCheckbox,
  incrementRequiredNodes,
  decrementRequiredNodes
} = templatesEditorSlice.actions;

export const selectListOrderField = (state) => state.templatesEditor.listOrderField;
export const selectListOrderDirection = (state) => state.templatesEditor.listOrderDirection;
export const selectNodeValue = (state, nodeId, key) => {
  if (!state.templatesEditor || !state.templatesEditor.nodesById) return null;
  return state.templatesEditor.nodesById?.[nodeId]?.[key];
}
export const selectParentNodeValue = (state, nodeId, key) => {
  if (!state.templatesEditor || !state.templatesEditor.nodesById) return null;
  const node = state.templatesEditor.nodesById?.[nodeId];
  const parentNode = state.templatesEditor.nodesById?.[node?.parentNodeId];

  return parentNode?.[key];
}

export const selectSaveButtonEnabled = (state) => state.templatesEditor.saveButtonEnabled;
export const selectNodeAddOpen = (state, openId) => state.templatesEditor.nodeAddOpen[openId];
export const selectNodeEditOpen = (state, openId) => state.templatesEditor.nodeEditOpen[openId];
export const selectIsAnyNodeAddOrEditOpen = (state) => {
  return Object.keys(state.templatesEditor.nodeAddOpen)?.length > 0 ||
    Object.keys(state.templatesEditor.nodeEditOpen)?.length > 0;
}

export const selectFirstLevelNodeIds = (state) => state.templatesEditor.firstLevelNodeIds;

export const selectClipboardCopyNodeId = (state) => state.templatesEditor.clipboard?.copy?.data?.id;
export const selectClipboard = (state, key) => state.templatesEditor.clipboard[key];
export const selectMacros = (state) => state.templatesEditor.data?.macros;

export const selectNodeIsInParent = (state, nodeId, parentId) => {
  let node = state.templatesEditor.nodesById[nodeId];
  let parent = state.templatesEditor.nodesById[parentId];
  while (node) {
    if (node?.id === parentId) return true;
    if (node?.macroId && node?.macroId === parent?.macroId) return true; // We are moving macro-section into itself?
    if (parent?.macroId && node?.macroId && selectNodeIsInMacro(state, node?.id, parent.macroId)) return true;
    node = state.templatesEditor.nodesById[node?.parentNodeId];
  }
  return false;
}

const macroIdIsInNodeTree = (nodes, macroId) => {
  return nodes?.some((node) => {
    return (node?.macroId === macroId || (node?.nodes && macroIdIsInNodeTree(node.nodes, macroId)));
  });
};

export const selectNodeIsInMacro = (state, nodeId, macroId) => {
  const originalNode = state.templatesEditor.nodesById[nodeId];
  if (!originalNode) {
    return false; // originalNode is probably root. It can't be in macro.
  }
  let node = state.templatesEditor.nodesById[nodeId];
  while (node) {
    if (node?.macroId === macroId) {
      return true;
    }
    node = state.templatesEditor.nodesById[node?.parentNodeId];
  }

  // Macro is not straight parent of node in node-tree, but it might be parent in macros.
  const macro = state.templatesEditor.data.macros[macroId];
  if (originalNode?.macroId && macroIdIsInNodeTree(macro?.node?.nodes, originalNode?.macroId)) {
    return true;
  }

  return false;
}

export const selectMoveToDocumentId = (state) => state.templatesEditor.moveToDocumentId;
export const selectDeleteReady = (state) => state.templatesEditor.deleteReady;
export const selectTemplateTitle = (state) => state.templatesEditor.data?.title || "";
export const selectTemplateCompanyId = (state) => state.templatesEditor.data?.company_id;

export const selectHasEditRights = (state) => {
  return state.templatesEditor.data?.company_id === state.auth.data?.company_id || !state.templatesEditor.id;
}

/**
 * It's possible that user is viewing template document he has no read rights.
 * This happens when he changes document.company_id. We don't want to redirect him away after change.
 * state.templatesEditor.enabled means there is comp_doctemplates-row for user company and for this document.
 */
export const selectHasReadRights = (state) => {
  return state.templatesEditor.data?.enabled || state.templatesEditor.data?.company_id === state.auth.data?.company_id;
}

export const selectTemplateDocumentTags = (state) => state.templatesEditor.data?.tags;
export const selectAvailableTags = (state) => state.templatesEditor.availableTags;
export const selectTagsGlobalEditMode = (state) => state.templatesEditor.tagsGlobalEditMode;
export const selectSearchTerm = (state) => state.templatesEditor.searchTerm;
export const selectTemplates = (state) => state.templatesEditor.templateList;
export const selectActiveTagFilters = (state) => state.templatesEditor.activeTagFilters;
export const selectTypeFilter = (state) => state.templatesEditor.typeFilter;

// This is executed only when some of the selected values change.
export const selectTemplateList = createSelector(
  [
    selectTemplates,
    selectSuperfolders,
    selectSearchTerm,
    selectActiveTagFilters,
    selectTypeFilter,
    selectListOrderField,
    selectListOrderDirection,
  ],
  (
    templates,
    superfolders,
    searchTerm,
    tags,
    type,
    orderField,
    orderDirection
  ) => {
    let list = null;

    if (type === 'document') {
      // list.sort() below modifies the array, we have to make a copy to make array editable
      list = templates.slice();
    } else if (type === 'folder') {
      list = superfolders.slice();
    } else {
      list = [...superfolders, ...templates];
    }

    if (orderField) {
      list.sort((a, b) => {
        if (orderField === 'title') {
          const aTitle = a.title || a.name || "";
          const bTitle = b.title || b.name || "";

          if (orderDirection === 'asc') {
            return bTitle.localeCompare(aTitle);
          } else {
            return aTitle.localeCompare(bTitle);
          }
        } else if (orderField === 'created_ts') {
          // There is no created_ts for superfolders, set id-value for them.
          // At least we can order superfolderlist to correct order.
          const aCreatedTs = a.created_ts || a.id;
          const bCreatedTs = b.created_ts || b.id;
          if (orderDirection === 'asc') {
            return aCreatedTs - bCreatedTs;
          } else {
            return bCreatedTs - aCreatedTs;
          }
        }
      });
    }

    if (!searchTerm?.length && !Object.keys(tags)?.length) {
      return list;
    }

    // This returns new array every time, 
    // but this is executed only when templates, search term, tags, type or ordering is changed.
    // Because this is created by createSelector().
    return list.filter(template => {
      return templateTagHelper.templateMatchesToAllFilterTags(template, tags) &&
        (
          template.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          template.name?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });
  }
);

export const selectTemplateListSidebarOpen = (state) => state.templatesEditor.templateListSidebarOpen;
export const selectFetchStatus = (state) => state.templatesEditor.fetchStatus;
export const selectListStatus = (state) => state.templatesEditor.listStatus;

export const selectCurrentPrintSettings = (state) => state.templatesEditor.currentPrintSettings;
export const selectSavePrintSettingsStatus = (state) => state.templatesEditor.savePrintSettingsStatus;

export const selectFileGroup = (state, parentNodeId, filename) => {
  return state.templatesEditor.nodesById?.[parentNodeId]?.fileGroupsByFilename?.[filename];
}

export const selectNodesById = (state) => state.templatesEditor.nodesById;
export const selectNewFiles = (state) => state.templatesEditor.newFilesByNodeAndFileId;

// This is used in ImageMoveModal. Have to use createSelector to prevent infinite render-loop
export const selectInputImageNodesById = createSelector(
  [
    selectNodesById,
    selectNewFiles
  ],
  (
    nodesById,
    newFiles,
  ) => {
    const imagesNodesById = {};
    for (const [nodeId, node] of Object.entries(nodesById)) {
      if (node.type === 'input-images') {
        const returnedNode = JSON.parse(JSON.stringify(node));
        const parentNode = nodesById[node.parentNodeId];
        returnedNode.hasNewFiles = node.childNodes.some(childNodeId => newFiles[childNodeId]);
        returnedNode.parentTitle = parentNode?.title || parentNode?.label || "";
        imagesNodesById[nodeId] = returnedNode;
      }
    }
    return imagesNodesById;
  }
);

// Adding files/images related
// There is 0 or 1 file per node, so we can return first
export const selectNewFile = (state, nodeId) => state.templatesEditor.newFilesByNodeAndFileId[nodeId] ? Object.values(state.templatesEditor.newFilesByNodeAndFileId[nodeId])[0] : null;

export const selectNewFilesCompressed = (state, nodeId) => {
  let hasFiles = false;
  for (const fileId in state.templatesEditor.newFilesByNodeAndFileId[nodeId]) {
    const newFile = state.templatesEditor.newFilesByNodeAndFileId[nodeId][fileId];
    if (!newFile.compressed) return false;
    hasFiles = true;
  }

  return hasFiles;
}

export const selectChildNodesNewFilesFirstNode = (state, nodeId) => {
  const node = state.templatesEditor.nodesById[nodeId];
  if (!node || !node.childNodes) {
    return false;
  }

  return node.childNodes.find(childNodeId => {
    return state.templatesEditor.newFilesByNodeAndFileId[childNodeId];
  });
}

export const selectGeolocationEnabled = (state) => state.templatesEditor.geolocationEnabled;
export const selectLaunchTemplateSave = (state) => state.templatesEditor.launchTemplateSave;
export const selectLaunchTemplateCreate = (state) => state.templatesEditor.launchTemplateCreate;
export const selectNode = (state, nodeId) => state.templatesEditor.nodesById[nodeId];

export const selectIsEditingThisTextNode = (state, path) => {
  // In redux toolkit/immer this comparison works, even when path is array and you wouldn't expect it to work.
  return state?.templatesEditor?.editingTextNodePath === path;
}
export const selectEditingTextNodePath = (state) => {
  return state?.templatesEditor?.editingTextNodePath;
}
export const selectTextFieldState = (state, nodeId) => {
  return state?.templatesEditor?.textFieldStates?.[nodeId];
}
export const selectNodeTextFormat = (state, nodeId) => {
  const defaultFormat = "plaintext";
  const node = state?.templatesEditor?.nodesById?.[nodeId];
  if (!node) return defaultFormat;
  if (node.type === 'input-textfield' || node.type === 'input-textarea') {
    return node?.value?.format || defaultFormat;
  } else if (node.type === 'note') {
    return node?.format || defaultFormat;
  } else {
    return defaultFormat;
  }
}
export const selectNodeText = (state, nodeId) => {
  const node = state?.templatesEditor?.nodesById?.[nodeId];
  if (!node) return "";
  if (node.type === 'input-textfield' || node.type === 'input-textarea') {
    return node?.value?.text || "";
  } else if (node.type === 'note') {
    return node?.text || "";
  } else {
    return "";
  }
}

export const selectIsInPastedNodeData = (state, nodeId) => {
  let node = state.templatesEditor.nodesById[nodeId];
  while (node) {
    if (state.templatesEditor.pastedNodeData?.some(d => d.targetNodeId === node.id)) {
      return true;
    }
    node = state.templatesEditor.nodesById[node?.parentNodeId];
  }
  return false;
}

export const selectCopyDocumentTitle = (state) => {
  const template = state.templatesEditor.templateList.find(t => Number(t.id) === Number(state.templatesEditor.copyDocumentId));

  return template?.title || "";
}

export default templatesEditorSlice.reducer;
